@import "src/tokens";

.root {
  @include screen(smallPhone) {
    padding-top: $space-ll;
    padding-bottom: $space-xl;
  }
  @include screen(phone) {
    padding-top: $space-ll;
    padding-bottom: $space-xl;
  }
  @include screen(tablet) {
    padding-top: $space-ll;
    padding-bottom: $space-xl;
  }
  @include screen(tabletLandscape) {
    padding-top: 128px;
    padding-bottom: 96px;
  }
  @include screen(desktop) {
    padding-top: 128px;
    padding-bottom: 96px;
  }
  @include screen(desktopWide) {
    padding-top: 128px;
    padding-bottom: 96px;
  }

  background-size: contain;
  background-repeat: repeat;
  position: relative;
  box-shadow: inset 0 24px 40px $dark-alpha-50;
}

.title {
  @include rf($heading-2, $heading-1);
  line-height: 52px;
  font-weight: $bold;
  letter-spacing: -0.024em;
  text-shadow: 0px 2px 4px $dark-alpha-60;
  padding-top: $space-xl;
  padding-bottom: $space-m;
  color: $light-hex-100;
  text-align: center;
}

.subtitle {
  font-size: 20px;
  line-height: 28px;
  font-weight: $medium;
  letter-spacing: -0.024em;
  color: $light-alpha-90;
  text-shadow: 0px 2px 4px $dark-alpha-60;
  text-align: center;
}

.searchWrapper {
  width: calc(100% - 48px);
  max-width: 960px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
  background-color: $bg-primary;
  box-shadow: 0px 12px 40px -8px $dark-alpha-40;
  display: flex;
  padding: 20px 20px 20px $space-l;
  border-radius: $radius-rounded;
  align-items: center;

  @include screen(smallPhone) {
    padding: 0;
  }
  @include screen(phone) {
    padding: 0;
  }
  @include screen(tablet) {
    padding: 0;
  }
  @include screen(tabletLandscape) {
    padding: 20px 20px 20px $space-l;
  }
  @include screen(desktop) {
    padding: 20px 20px 20px $space-l;
  }
  @include screen(desktopWide) {
    padding: 20px 20px 20px $space-l;
  }
}

.fieldWrapper {
  cursor: pointer;
  flex: 1;
  padding-right: $space-sm;
  @include screen(smallPhone) {
    display: none;
  }
  @include screen(phone) {
    display: none;
  }
  @include screen(tablet) {
    display: none;
  }
  @include screen(tabletLandscape) {
    display: block;
  }
  @include screen(desktop) {
    display: block;
  }
  @include screen(desktopWide) {
    display: block;
  }
}

.field {
  font-size: 16px;
  line-height: 24px;
  color: $dark-hex-80;
  width: 100%;
  appearance: none;
  font-weight: $medium;
}

.buttonWrapper {
  cursor: pointer;
  background-color: $brand-primary-200;
  border-radius: $radius-rounded;
  color: $light-hex-100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  transition: all 0.4s ease-in-out;
  box-shadow: 0px 0px 0px 8px $brand-primary-200;

  &:hover {
    transition: all 0.28s ease-in-out;
    background-image: linear-gradient(
      45deg,
      $brand-primary-200,
      $brand-primary-300
    );
  }

  @include screen(smallPhone) {
    min-width: 100%;
    height: 48px;
  }
  @include screen(phone) {
    min-width: 100%;
    height: 48px;
  }
  @include screen(tablet) {
    min-width: 100%;
    height: 48px;
  }
  @include screen(tabletLandscape) {
    min-width: 48px;
    height: 48px;
  }
  @include screen(desktop) {
    min-width: 48px;
    height: 48px;
  }
  @include screen(desktopWide) {
    min-width: 48px;
    height: 48px;
  }
}

.searchIcon {
  width: 16px;
  height: 16px;
}

.searchText {
  margin-left: $space-s;
  font-size: 15px;
  line-height: 16px;
  font-weight: $semibold;
  text-transform: uppercase;
  letter-spacing: 0.1em;

  @include screen(smallPhone) {
    display: block;
  }
  @include screen(phone) {
    display: block;
  }
  @include screen(tablet) {
    display: block;
  }
  @include screen(tabletLandscape) {
    display: block;
  }
  @include screen(desktop) {
    display: block;
  }
  @include screen(desktopWide) {
    display: block;
  }
}

.label {
  display: block;
  font-size: 12px;
  font-weight: $semibold;
  color: $dark-alpha-70;
  padding-bottom: $space-s;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.divider {
  width: 1px;
  height: 40px;
  background-color: $dark-alpha-40;
  margin-left: 24px;
  margin-right: 24px;
  @include screen(smallPhone) {
    display: none;
  }
  @include screen(phone) {
    display: none;
  }
  @include screen(tablet) {
    display: none;
  }
  @include screen(tabletLandscape) {
    display: block;
  }
  @include screen(desktop) {
    display: block;
  }
  @include screen(desktopWide) {
    display: block;
  }
}

.lowongan {
  @include screen(smallPhone) {
    display: inline;
  }
  @include screen(phone) {
    display: inline;
  }
  @include screen(tablet) {
    display: inline;
  }
  @include screen(tabletLandscape) {
    display: none;
  }
  @include screen(desktop) {
    display: none;
  }
  @include screen(desktopWide) {
    display: none;
  }
}

.highlight {
  padding: 6px;
  background-color: $brand-primary-70;
  width: fit-content;
  display: block;
  text-align: center;
  display: flex;
  justify-content: center;
}

.link {
  color: #ffffff;
  font-weight: $semibold;
  text-decoration: underline;
  text-underline-offset: 3px;
}
