$layer-behind: -9;
$layer-reset: auto;

$layer-s: 9;
$layer-sm: 99;
$layer-m: 999;
$layer-ml: 9999;
$layer-l: 99999;
$layer-ll: 999999;
$layer-xl: 9999999;

$layer-top: 999999999;
