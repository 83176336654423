@import "src/tokens";

.root {
  width: 100%;
  &.critical {
  }
  &.positive {
  }
  &.warning {
  }
  &.info {
  }
  &.brand {
    background-color: $brand-primary-200;
    color: var(--tenka-light-100);
  }
}
