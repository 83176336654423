/*
  Spaces tokens
*/

$base: 16px;
$row-height: 4px;

$space-default: 1rem;
$space-xxs: 0.125rem;
$space-xs: 0.25rem;
$space-s: 0.5rem;
$space-sm: 0.75rem;
$space-m: 1rem;
$space-ml: 1.5rem;
$space-l: 2rem;
$space-ll: 3rem;
$space-xl: 4rem;
$space-xxl: 6rem;

$space-huge: 7rem;

$space-field: 20px;

$space-inset-default: $space-m $space-m $space-m $space-m;
$space-inset-xxs: $space-xxs $space-xxs $space-xxs $space-xxs;
$space-inset-xs: $space-xs $space-xs $space-xs $space-xs;
$space-inset-s: $space-s $space-s $space-s $space-s;
$space-inset-sm: $space-sm $space-sm $space-sm $space-sm;
$space-inset-m: $space-m $space-m $space-m $space-m;
$space-inset-ml: $space-ml $space-ml $space-ml $space-ml;
$space-inset-l: $space-l $space-l $space-l $space-l;
$space-inset-ll: $space-ll $space-ll $space-ll $space-ll;
$space-inset-xl: $space-xl $space-xl $space-xl $space-xl;

$space-stack-default: 0 0 $space-m 0;
$space-stack-xxs: 0 0 $space-xxs 0;
$space-stack-xs: 0 0 $space-xs 0;
$space-stack-s: 0 0 $space-s 0;
$space-stack-sm: 0 0 $space-sm 0;
$space-stack-m: 0 0 $space-m 0;
$space-stack-ml: 0 0 $space-ml 0;
$space-stack-l: 0 0 $space-l 0;
$space-stack-ll: 0 0 $space-ll 0;
$space-stack-xl: 0 0 $space-xl 0;

$space-inline-default: 0 $space-m 0 0;
$space-inline-xxs: 0 $space-xxs 0 0;
$space-inline-xs: 0 $space-xs 0 0;
$space-inline-s: 0 $space-s 0 0;
$space-inline-sm: 0 $space-sm 0 0;
$space-inline-m: 0 $space-m 0 0;
$space-inline-ml: 0 $space-ml 0 0;
$space-inline-l: 0 $space-l 0 0;
$space-inline-ll: 0 $space-ll 0 0;
$space-inline-xl: 0 $space-xl 0 0;

$space-vertical-default: $space-sm 0 $space-sm 0;
$space-vertical-xs: $space-xs 0 $space-xs 0;
$space-vertical-s: $space-s 0 $space-s 0;
$space-vertical-sm: $space-sm 0 $space-sm 0;
$space-vertical-m: $space-m 0 $space-m 0;
$space-vertical-ml: $space-ml 0 $space-ml 0;
$space-vertical-l: $space-l 0 $space-l 0;
$space-vertical-ll: $space-ll 0 $space-ll 0;
$space-vertical-xl: $space-xl 0 $space-xl 0;
$space-vertical-xxl: $space-xxl 0 $space-xxl 0;

$space-horizontal-default: 0 $space-sm 0 $space-sm;
$space-horizontal-xs: 0 $space-xs 0 $space-xs;
$space-horizontal-s: 0 $space-s 0 $space-s;
$space-horizontal-sm: 0 $space-sm 0 $space-sm;
$space-horizontal-m: 0 $space-m 0 $space-m;
$space-horizontal-ml: 0 $space-ml 0 $space-ml;
$space-horizontal-l: 0 $space-l 0 $space-l;
$space-horizontal-xl: 0 $space-xl 0 $space-xl;

$space-squish-default: $space-sm $space-m $space-sm $space-m;
$space-squish-s: $space-xs $space-s $space-xs $space-s;
$space-squish-sm: $space-s $space-sm $space-s $space-sm;
$space-squish-m: $space-sm $space-m $space-sm $space-m;
$space-squish-ml: $space-m $space-ml $space-m $space-ml;
$space-squish-l: $space-ml $space-l $space-ml $space-l;
$space-squish-xl: $space-l $space-xl $space-l $space-xl;

$space-stretch-default: $space-m $space-sm $space-m $space-sm;
$space-stretch-xs: $space-s $space-xs $space-s $space-xs;
$space-stretch-s: $space-sm $space-s $space-sm $space-s;
$space-stretch-sm: $space-m $space-sm $space-m $space-sm;
$space-stretch-m: $space-ml $space-m $space-ml $space-m;
$space-stretch-ml: $space-l $space-ml $space-l $space-ml;
$space-stretch-l: $space-xl $space-l $space-xl $space-l;
