@import "src/tokens";

.root {
  display: flex;
  column-gap: var(--tenka-space-sm);
  row-gap: var(--tenka-space-ml);
  width: 100%;

  @include screen(smallPhone) {
    flex-direction: column;
    padding-top: var(--tenka-space-xl);
    padding-bottom: var(--tenka-space-xl);
  }
  @include screen(phone) {
    flex-direction: column;
    padding-top: var(--tenka-space-xl);
    padding-bottom: var(--tenka-space-xl);
  }
  @include screen(tablet) {
    flex-direction: column;
    padding-top: var(--tenka-space-xl);
    padding-bottom: var(--tenka-space-xl);
  }
  @include screen(tabletLandscape) {
    flex-direction: row;
    padding-top: var(--tenka-space-xxl);
    padding-bottom: var(--tenka-space-xxl);
  }
  @include screen(desktop) {
    flex-direction: row;
    padding-top: var(--tenka-space-xxl);
    padding-bottom: var(--tenka-space-xxl);
  }
  @include screen(desktopWide) {
    flex-direction: row;
    padding-top: var(--tenka-space-xxl);
    padding-bottom: var(--tenka-space-xxl);
  }
}

.card {
  position: relative;
  flex: 1;
  padding: var(--tenka-space-ml);
  border: 1px solid var(--tenka-dark-30);
  border-radius: var(--tenka-radius-m);
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
  // box-shadow: 0px 8px 12px -4px var(--tenka-dark-30);
  z-index: 0;
  @include screen(smallPhone) {
    min-height: 192px;
  }
  @include screen(phone) {
    min-height: 192px;
  }
  @include screen(tablet) {
    min-height: 192px;
  }
  @include screen(tabletLandscape) {
    min-height: 296px;
  }
  @include screen(desktop) {
    min-height: 296px;
  }
  @include screen(desktopWide) {
    min-height: 296px;
  }
}

.tower {
  position: absolute;
  pointer-events: none;
  user-select: none;
  width: auto;
  height: 120%;
  bottom: -8px;
  right: 10%;
  z-index: -1;
}

.calendar {
  position: absolute;
  z-index: -1;
  pointer-events: none;
  user-select: none;
  @include screen(smallPhone) {
    width: 60%;
    right: -10%;
    bottom: 0px;
  }
  @include screen(phone) {
    width: 60%;
    right: -10%;
    bottom: 0px;
  }
  @include screen(tablet) {
    width: 50%;
    right: -10%;
    bottom: -64px;
  }
  @include screen(tabletLandscape) {
    width: 80%;
    right: -20%;
    bottom: 10%;
  }
  @include screen(desktop) {
    width: 80%;
    right: -20%;
    bottom: 10%;
  }
  @include screen(desktopWide) {
    width: 80%;
    right: -20%;
    bottom: 10%;
  }
}

.trainer {
  position: absolute;
  z-index: -1;
  pointer-events: none;
  user-select: none;
  width: auto;
  @include screen(smallPhone) {
    height: 97%;
    bottom: -8px;
    right: 4%;
  }
  @include screen(phone) {
    height: 92%;
    bottom: -8px;
    right: 4%;
  }
  @include screen(tablet) {
    height: 92%;
    bottom: -8px;
    right: 10%;
  }
  @include screen(tabletLandscape) {
    height: 92%;
    bottom: -8px;
    right: 2%;
  }
  @include screen(desktop) {
    height: 92%;
    bottom: -8px;
    right: 2%;
  }
  @include screen(desktopWide) {
    height: 92%;
    bottom: -8px;
    right: 2%;
  }
}

.text {
  padding-bottom: var(--tenka-space-s);
  z-index: 1;
  font-weight: var(--tenka-weight-600);
  @include screen(smallPhone) {
    max-width: 50%;
  }
  @include screen(phone) {
    max-width: 50%;
  }
  @include screen(tablet) {
    max-width: 50%;
  }
  @include screen(tabletLandscape) {
    max-width: 60%;
  }
  @include screen(desktop) {
    max-width: 60%;
  }
  @include screen(desktopWide) {
    max-width: 60%;
  }
}

.link {
  padding-top: var(--tenka-space-s);
  padding-bottom: var(--tenka-space-s);
  font-weight: var(--tenka-weight-500);
  // background-color: rgba(255, 255, 255, 0.6);
  // backdrop-filter: blur(2px);
  margin-left: -8px;
  margin-right: -8px;
  padding-left: 8px;
  padding-right: 8px;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.subtitle {
  max-width: 50%;
  padding-bottom: var(--tenka-space-ml);
}

.partner {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;

  @include screen(smallPhone) {
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: -24px;
  }
  @include screen(phone) {
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: -24px;
  }
  @include screen(tablet) {
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: -24px;
  }
  @include screen(tabletLandscape) {
    align-items: flex-end;
    justify-content: flex-start;
    margin-top: -32px;
  }
  @include screen(desktop) {
    align-items: flex-end;
    justify-content: flex-start;
    margin-top: -32px;
  }
  @include screen(desktopWide) {
    align-items: flex-end;
    justify-content: flex-start;
    margin-top: -32px;
  }
}

.logo {
  object-fit: contain;
  object-position: center center;
  width: 128px;
  height: 32px;
  margin-left: -6px;
}
