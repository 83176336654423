@import "src/tokens";

.root {
  position: relative;
  background-color: $dark-alpha-05;
}

.wrapper {
  display: grid;
  column-gap: $space-xl;
  row-gap: $space-ml;

  @include screen(smallPhone) {
    padding-top: $space-xl;
    padding-bottom: $space-ll;
    grid-template-columns: 1fr;
  }
  @include screen(phone) {
    padding-top: $space-xl;
    padding-bottom: $space-ll;
    grid-template-columns: 1fr;
  }
  @include screen(tablet) {
    padding-top: $space-xl;
    padding-bottom: $space-ll;
    grid-template-columns: 1fr;
  }
  @include screen(tabletLandscape) {
    padding-top: $space-xl;
    padding-bottom: $space-xl;
    grid-template-columns: 2fr 1fr 1fr;
  }
  @include screen(desktop) {
    padding-top: $space-xl;
    padding-bottom: $space-xl;
    grid-template-columns: 2fr 1fr 1fr;
  }
  @include screen(desktopWide) {
    padding-top: $space-xl;
    padding-bottom: $space-xl;
    grid-template-columns: 2fr 1fr 1fr;
  }
}

.header {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
}

.links {
  display: flex;
  justify-content: flex-end;
}

.link {
  font-size: 15px;
  line-height: 20px;
  font-weight: $medium;
  padding-left: $space-ml;
  padding-right: $space-ml;
  color: $light-alpha-100;
}

.pattern {
  background: url("https://talentindonesia.s3.ap-southeast-1.amazonaws.com/assets/jp/pattern.png");
  background-size: 200px 200px;
  width: 100vw;
  height: 48px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -99999;
}

.bandWrapper {
  background-color: $brand-primary-200;
}

.band {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.linkAlt {
  padding-top: 24px;
  padding-bottom: 24px;
  color: $light-alpha-100;

  &:not(:first-child) {
    padding-left: 24px;
  }
  &:not(:last-child) {
    padding-right: 24px;
  }
}

.menuList {
  width: 100%;
  padding-top: $space-ml;
  padding-bottom: $space-ml;
}

.menu {
  @include screen(smallPhone) {
    width: 100%;
    padding-top: $space-sm;
    padding-bottom: $space-sm;
    border-bottom: 1px solid $dark-alpha-20;
  }
  @include screen(phone) {
    width: 100%;
    padding-top: $space-sm;
    padding-bottom: $space-sm;
    border-bottom: 1px solid $dark-alpha-20;
  }
  @include screen(tablet) {
    width: 100%;
    padding-top: $space-sm;
    padding-bottom: $space-sm;
    border-bottom: 1px solid $dark-alpha-20;
  }
  @include screen(tabletLandscape) {
    width: fit-content;
    padding-top: $space-s;
    padding-bottom: $space-s;
    border-bottom: none;
  }
  @include screen(desktop) {
    width: fit-content;
    padding-top: $space-s;
    padding-bottom: $space-s;
    border-bottom: none;
  }
  @include screen(desktopWide) {
    width: fit-content;
    padding-top: $space-s;
    padding-bottom: $space-s;
    border-bottom: none;
  }
}

.socialList {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: $space-ml;
}

.social {
  display: block;
  text-decoration: none;
  &:not(:first-child) {
    margin-left: $space-m;
  }

  &:not(:last-child) {
    margin-right: $space-m;
  }
}

.icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}

.logo {
  height: 64px;
  margin-bottom: $space-ml;
}

.copy {
  display: block;
  padding-top: $space-ml;
  border-top: 1px solid $dark-alpha-30;

  @include screen(smallPhone) {
    text-align: left;
    padding-bottom: $space-xxl;
  }
  @include screen(phone) {
    text-align: left;
    padding-bottom: $space-xxl;
  }
  @include screen(tablet) {
    text-align: left;
    padding-bottom: $space-xxl;
  }
  @include screen(tabletLandscape) {
    text-align: center;
    padding-bottom: $space-ml;
  }
  @include screen(desktop) {
    text-align: center;
    padding-bottom: $space-ml;
  }
  @include screen(desktopWide) {
    text-align: center;
    padding-bottom: $space-ml;
  }
}
