@import "src/tokens";

.root {
  @include screen(smallPhone) {
    padding-top: $space-xxl;
  }
  @include screen(phone) {
    padding-top: $space-xxl;
  }
  @include screen(tablet) {
    padding-top: $space-xxl;
  }
  @include screen(tabletLandscape) {
    padding-top: 144px;
  }
  @include screen(desktop) {
    padding-top: 144px;
  }
  @include screen(desktopWide) {
    padding-top: 144px;
  }
  background-image: linear-gradient(
    180deg,
    $brand-primary-30,
    $brand-primary-20
  );
}

.title {
  @include rf($heading-3, $heading-2);
  letter-spacing: -1.2px;
  font-weight: $bold;
  letter-spacing: -0.024em;
  line-height: 1.35;
  text-align: center;
}

.gridWrapper {
  display: grid;
  column-gap: $space-xl;
  row-gap: $space-ll;
  padding-top: $space-xxl;

  @include screen(smallPhone) {
    grid-template-columns: 1fr;
  }
  @include screen(phone) {
    grid-template-columns: 1fr;
  }
  @include screen(tablet) {
    grid-template-columns: 1fr;
  }
  @include screen(tabletLandscape) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @include screen(desktop) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @include screen(desktopWide) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.benefitWrapper {
}

.iconWrapper {
  display: block;
  margin-bottom: $space-l;
  color: $brand-primary-300;
}

.icon {
  @include screen(smallPhone) {
    width: 56px;
    height: 56px;
  }
  @include screen(phone) {
    width: 56px;
    height: 56px;
  }
  @include screen(tablet) {
    width: 56px;
    height: 56px;
  }
  @include screen(tabletLandscape) {
    width: 72px;
    height: 72px;
  }
  @include screen(desktop) {
    width: 72px;
    height: 72px;
  }
  @include screen(desktopWide) {
    width: 72px;
    height: 72px;
  }
}

.benefitTitle {
  padding-bottom: $space-m;
}

.benefitDetails {
}

.dialogArea {
  z-index: $layer-l;
  background-color: $dark-alpha-80;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}

.dialogContent {
  width: 100%;
  background-color: $bg-primary;
  box-shadow: 0px 12px 24px -8px $dark-alpha-50;
  margin: 10vh auto;
  padding: $space-sm;
  border-radius: $radius-card-alt;
  outline: none;

  @include screen(smallPhone) {
    max-width: calc(100vw - 32px);
  }
  @include screen(phone) {
    max-width: calc(100vw - 32px);
  }
  @include screen(tablet) {
    max-width: calc(100vw - 32px);
  }
  @include screen(tabletLandscape) {
    max-width: 560px;
  }
  @include screen(desktop) {
    max-width: 560px;
  }
  @include screen(desktopWide) {
    max-width: 560px;
  }
}

.dialogFooter {
  padding-top: $space-m;
  padding-bottom: $space-s;
  display: flex;
  justify-content: center;
}

.illustration {
  width: 100%;
  border-radius: $radius-card-alt;
  overflow: hidden;
}

.paragraph {
  padding-left: $space-m;
  padding-right: $space-m;
  padding-top: $space-m;
}

.dialogTitle {
  padding-left: $space-m;
  padding-right: $space-m;
  padding-top: $space-m;
}

.syarat {
  padding-left: $space-l;
  list-style: disc;
}

.benefitTrigger {
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  margin-top: $space-sm;
  font-weight: $medium;
  color: $brand-primary-300;
}
