// Mixins
@import "./mixins/flexbox.scss";
@import "./mixins/media-queries.scss";
@import "./mixins/container.scss";
@import "./mixins/fit.scss";
@import "./mixins/grid.scss";
@import "./mixins/link-animation.scss";
@import "./mixins/responsive-text.scss";
@import "./mixins/text-crop.scss";
@import "./mixins/text-shadow.scss";
@import "./mixins/to-rem.scss";
@import "./mixins/type.scss";

@function upperSize($number) {
  @return $number * 1.618;
}

@function lowerSize($number) {
  @return $number / 1.618;
}

@mixin clearfix {
  &::before {
    display: table;
    content: "";
  }

  &::after {
    display: table;
    clear: both;
    content: "";
  }
}

@mixin fieldInput {
  border: 1px solid;
  border-radius: 6px;
  border-color: $dark-alpha-30;
  transition: all 0.4s ease-in;
  box-shadow: none;

  &::placeholder {
    color: $dark-alpha-60;
  }

  &:focus,
  &:active {
    border-color: $dark-hex-80;
    box-shadow: 0px 0px 0px 1px $dark-hex-80;
    transition: all 0.22s ease-out;
  }
}

@mixin section-space {
  @include screen(smallPhone) {
    padding-top: $space-ll;
    padding-bottom: $space-ll;
  }
  @include screen(phone) {
    padding-top: $space-ll;
    padding-bottom: $space-ll;
  }
  @include screen(tablet) {
    padding-top: $space-ll;
    padding-bottom: $space-ll;
  }
  @include screen(tabletLandscape) {
    padding-top: 128px;
    padding-bottom: 128px;
  }
  @include screen(desktop) {
    padding-top: 128px;
    padding-bottom: 128px;
  }
  @include screen(desktopWide) {
    padding-top: 128px;
    padding-bottom: 128px;
  }
}
