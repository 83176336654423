@import "src/tokens";

.content {
  position: relative;
  padding-top: $space-xl;
  padding-bottom: $space-xl;
  z-index: 1;
  background-color: $bg-primary;
  min-height: calc(100vh - 64px);
}

.fullContent {
  position: relative;
  z-index: 1;
  // min-height: calc(100vh - 64px);

  &:not(.gray) {
    background-color: $bg-primary;
  }

  &.gray {
    background-color: $bg-gray;
  }
}

.contentWrapper {
  position: relative;
}

.content {
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr;
  padding-top: $space-xl;

  &:not(.gray) {
    background-color: $bg-primary;
  }

  &.gray {
    background-color: $bg-gray;
  }
}

.main {
  position: relative;
}

.link {
  font-weight: var(--tenka-weight-500);
  color: var(--tenka-light-100);
  text-decoration: underline;
  text-underline-offset: 4px;
}
