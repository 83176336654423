@import "src/tokens";

.root {
  width: 100%;
  padding-top: $space-ml;
  padding-bottom: $space-ml;
  background-color: rgba(255, 227, 128, 0.3);
  color: $dark-hex-80;
}

.sent {
  padding-top: $space-sm;
  color: $tone-success-200;
}
