@import "src/tokens";

.root {
  // Properties goes here
  @include section-space;
  background-color: $brand-primary-20;
}

.title {
  @include rf(20px, $heading-3);
  line-height: 1.45;
  letter-spacing: -0.48px;
  letter-spacing: -0.024em;
  padding-bottom: $space-m;
  font-weight: $semibold;
  text-align: center;
}

.footer {
  padding-top: $space-ll;
  display: flex;
  justify-content: center;
  align-items: center;
}
